/* You can add global styles to this file, and also import other style files */

// Load some base level theme and override it further down
@import "@angular/material/prebuilt-themes/indigo-pink.css";
// Import material icons
@import "material-icons/iconfont/material-icons.scss";

.light-theme {
  @import "./modules/material/themes/light.scss";
}

.dark-theme {
  @import "./modules/material/themes/dark.scss";
}

html,
body {
  height: 100%;
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.dark-theme
  .mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float
  .mat-form-field-label,
.dark-theme
  .mat-form-field-appearance-legacy.mat-form-field-can-float
  .mat-input-server:focus
  + .mat-form-field-label-wrapper
  .mat-form-field-label {
  // Fixes an angular CDK bug when rendering labels in chrome causing them to look blurry
  transform: translateY(-1.34373em) scale(0.75);
  width: 133.3333933333%;
}

button {
  // Fix issues where buttons could end up smaller than their content
  min-width: fit-content !important;
}
