// This file is used to define basic styling that should be shared across light themes
@import "./base.scss";

body {
  --text-color: black;
}

.logo {
  content: url("../../../../assets/logo/black.png");
}

.str-logo {
  content: url("../../../../assets/str/logo-black.svg");
}

.mat-menu-content {
  background-color: var(--background-color);
}
