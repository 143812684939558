// This file is used to define basic styling that should be shared across dark themes
@import "./base.scss";

body {
  --text-color: white;
}

.logo {
  content: url("../../../../assets/logo/white.png");
}

.str-logo {
  content: url("../../../../assets/str/logo-white.svg");
}

.mat-menu-content {
  background-color: var(--primary-lighter-color) !important;
}

.mat-accent .mat-option.mat-selected:not(.mat-option-disabled) {
  // Fixes dark theme accent dropdown coloring
  color: var(--accent-lighter-color) !important;
}
