/* ===== Scrollbar CSS ===== */
/* Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: var(--accent-color) transparent;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 0.75rem;
}

*::-webkit-scrollbar-track {
  background: transparent;
}

*::-webkit-scrollbar-thumb {
  background-color: var(--accent-color);
  border-radius: 1rem;
  border: 0.15rem solid black;
}

::-webkit-scrollbar-corner {
  background: rgba(0, 0, 0, 0);
}
