// The following options are to enforce white styling if you would like for your checkbox
.checkbox-white .mat-checkbox .mat-checkbox-frame {
  border-color: white;
}

.checkbox-white .mat-checkbox .mat-checkbox-background {
  background-color: white !important;
  transition: none;
  animation: none;
}
