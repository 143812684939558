// This file is used to define basic styling that should be shared across dark themes
@import "./scrollbar.scss";
@import "./checkbox.scss";

body {
  --text-color: white;
}

.logo-dark {
  content: url("../../../../assets/logo/black.png");
}

.logo-light {
  content: url("../../../../assets/logo/white.png");
}

.str-logo-dark {
  content: url("../../../../assets/str/logo-white.svg");
}

.str-logo-light {
  content: url("../../../../assets/str/logo-black.svg");
}

/// This ensures that the tab bar displays closely to the sidenav title and uses a bigger text size.
/// We need to do this here as it's overriding the mat-toolbar component and there wasn't another good way to get access
/// to styling this component.
div.mat-tab-label {
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
  margin-right: 5px;
}

div.mat-tab-label:last-child {
  margin-right: 0px;
}

/// This ensures our active tab bar item continues to display as highlighted even when the user clicks off the tab bar.
/// not sure why this isn't the default behavior personally.
div.mat-tab-label.mat-tab-label-active {
  background-color: var(--accent-color);
  opacity: 1;
  color: var(--text-color);
}

mat-tab-header.mat-tab-header {
  border-bottom: none;
}

.mat-tab-body-wrapper {
  transition: none;
  height: 100%;
}

/// Disable the ink bar as it doesn't look good below the tab bars in our style.
.mat-ink-bar {
  display: none;
}

// Hide input numbers
input[matinput]::-webkit-outer-spin-button,
input[matinput]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[matinput][type="number"] {
  -moz-appearance: textfield;
}

.mat-form-field {
  // Enforce weird letter spacing
  letter-spacing: normal;
}

.mat-menu-panel {
  min-height: 0;
}

.mat-menu-content:not(:empty) {
  padding: 0;
}
